// when load
// $(window).load(function() {
// });

$(document).ready(function(){

    /*** Chosen ***/
    $(".chosen-select").chosen({disable_search_threshold: 10, width:'auto' }); 

    /*** Datetimepicker ***/
    $('#datepicker').datetimepicker({
        timepicker:false,
        format:'d/m/Y'
    });

    /*** tooltipster ***/
    $('.tooltip').tooltipster();

    /*** when click .add-addrs append element input ***/
    $(".add-addrs").click(function(event){
        event.preventDefault();
        var adddrs = $(this).attr("href");
        $(adddrs).show();
    });   

    /*** Input Radio type (Expand/Collapse) ***/
    $('input[data-input=radio-collapse]').on('change', function () {
        if (!$(this).checked) {
            $('.radio__expand-collapse').not($('div.' + $(this).attr('class'))).slideUp();
            $('.radio__expand-collapse.' + $(this).attr('class')).slideDown();
        }
    });

    /* character countdown in textarea gift card note product detail */
    var maxLength = 250;
    $('#gift-card-note, .gift-card-note').keyup(function(){
        var length = $(this).val().length;
        var length = maxLength-length;
        $(this).parent().find('#chars').text(length);
    });

    /*** Checkbox (Recipient) ***/
    $('.recipient-check').change(function() {
        var recp = $(this).attr("id");
        if ($(this).is(':checked')) {            
            $("."+recp).removeClass("hide");
        } else {
            $("."+recp).addClass("hide");
        }
    });

    /*** shopping cart detail item when edit ***/
    $(".btn--edit-sc-item").click(function(event) {
        event.preventDefault();
        var scitem = $(this).attr("href");
        $(scitem).hide();
        $(scitem + "__edit").show();
    });
    $(".btn--save-sc-item").click(function(event) {
        event.preventDefault();
        var scitem = $(this).attr("href");
        $(scitem).show();
        $(scitem + "__edit").hide();
    });

    /*** adressbook on account when edit ***/
    $(".btn--edit-ad-item").click(function(event) {
        event.preventDefault();
        var addata = $(this).attr("id");
        $('#' + addata).hide();
        $('#' + addata + "__edit").show();

        $('.shipaddrs-edit').not($('#' + addata + "__edit")).hide();
        $('.btn--box__small').not($('#' + addata)).show();
    });
    $(document).on('click', '.btn--save-ad-item', function(event) {
        event.preventDefault();
        var addata = $(this).attr("id");
        $('#' + addata).show();
        // $('#' + addata + "__edit").hide();
    });

    // // SHIPPING ADDRESS HIDE SHOW FORM
    // $(document).on('click', '.btn-form-shipping-address-show', function(){
    //     $('.form-shipping-address').show();
    //     $(this).parents('.list-shipping-address').hide();
    // });
    // $(document).on('click', '.btn-form-shipping-address-save, .btn-form-shipping-address-cancel', function(){
    //     $('.list-shipping-address').show();
    //     $(this).parents('.form-shipping-address').hide();
    // });

   /*** adressbook on account when add ***/
    $(".btn--add-ad-item").click(function(event) {
        event.preventDefault();
        var addata = $(this).attr("id");
        $('#' + addata).hide();
        $('#' + addata + "__add").show();
    });
    // $(".btn--add-save-item").click(function(event) {
    //     event.preventDefault();
    //     var addata = $(this).attr("id");
    //     $('#' + addata).show();
    //     $('#' + addata + "__add").hide();
    // });

    /*** popup ***/
    $(".popup__link").click(function(event) {
        event.preventDefault();
        var tab = $(this).attr("href");
        $(tab).addClass("active");
        $("#body").css("overflow", "hidden");
        if ($(tab).find(".mnavbar")){
            $(".mnavbar").addClass("active");
        }
        if ($(tab).find(".msearchbar")){
            $(".msearchbar").addClass("active");
        }
        else{}
    });
    $(".popup__wrapper, .ico-popupclose, .mnavbar__wrapper, .arrowleft__wrapper, .msearchbar__close").not(".popup__box").click(function(e) {
        $(".popup__wrapper, .mnavbar__wrapper, .mnavbar, .msearchbar__wrapper, .msearchbar").removeClass("active");
        $("#body").css("overflow", "auto");
    });
    $(".popup__box, .mnavbar").click(function(e) {
        e.stopPropagation();
    });

    /*** account Tab ***/
    $(".tab-link__ul li:first").addClass("active");
    $(".tab-link__ul li a").on('click', function(event) {
        event.preventDefault();
        $(this).closest('li').addClass("active").siblings().removeClass("active");
        var tab = $(this).attr("href");
        $(".tab-container").not(tab).css("display", "none");
        $(tab).fadeIn();
        history.pushState(null, null, tab);
    });

    $('.sub-account__a').on('click', function(){
        // $(".tab-link__ul li a").trigger('click');
        var href = $(this).attr("href"),
            hash = href.substr(href.indexOf("#"));

        $(hash).fadeIn();
        $('.tab-container').not(hash).hide();
        $(".tab-link__ul li a").parents('li').addClass("active");
        $(".tab-link__ul li a").not(hash + '-sidebar').parents('li').removeClass("active");
    });

    /*** invoince data-order with hash ***/
    function invoicehash(){
        /////// when click, show invoice with hash
        $(".order-history  td a").click(function(event) {
            event.preventDefault();
            var id = $(this).attr("id");
            var hash = id.substr(id.indexOf("#"));

            if(window.location.hash) {
                // give hash without jumping
                if(history.pushState) {
                    history.pushState(null, null, '#orderhistory');
                }
                else { location.hash = '#orderhistory'; }
                location.replace(document.URL+hash);                    
                $(".invoice").not(hash).css({"display":"none"});
                $(hash).fadeIn('slow');                   
            } 
            else {
                location.replace(document.URL+hash);                    
                $(".invoice").not(hash).css({"display":"none"});
                $(hash).fadeIn('slow');
            }
        });
    }
    invoicehash();

    // when hash is
    if(window.location.hash) {        
        // call function
        // hash
        var thehash = window.location.hash;
        var hashpart = thehash.substring(13, thehash.length);
        // #profilesettings
        if (thehash == "#profilesettings"){
            $(body).scrollTop(0);
            $(".tab-link__ul li:nth-child(1)").addClass("active").siblings().removeClass("active");
            $(".tab-container").not(thehash).css("display", "none");
            $(thehash).fadeIn();   
        }
        // #addressbook
        else if (thehash == "#addressbook"){
            $(".tab-link__ul li:nth-child(2)").addClass("active").siblings().removeClass("active");
            $(".tab-container").not(thehash).css("display", "none");
            $(thehash).fadeIn();   
        }
        // #paymentconfirmation
        else if (thehash == "#paymentconfirmation"){
            $(".tab-link__ul li:nth-child(3)").addClass("active").siblings().removeClass("active");
            $(".tab-container").not(thehash).css("display", "none");
            $(thehash).fadeIn();   
        }
        // #orderhistory
        else if (thehash == "#orderhistory"){
            if(history.pushState) {
                history.pushState(null, null, '#orderhistory');
            }
            else { location.hash = '#orderhistory'; }
            $(".tab-link__ul li:nth-child(4)").addClass("active").siblings().removeClass("active");            
            $(".tab-container").not(thehash).css("display", "none");
            $(thehash).fadeIn();                     
            // invoice tab with hash
            invoicehash();               
        }
        // #orderhistory#no.invoice        
        else if (thehash == ("#orderhistory"+hashpart)){
            $(".tab-link__ul li:nth-child(4)").addClass("active").siblings().removeClass("active");            
            $(".tab-container").not("#orderhistory").css("display", "none");
            $("#orderhistory").fadeIn();

            $(".invoice").not(hashpart).css({"display":"none"});
            $(hashpart).fadeIn('slow');
            // alert(hashpart);    
        }
        else{}      
    }   
    
    /*** min max input qty ***/
    // This button will increment the value
    $('.qtyplus').click(function(e){
        // Stop acting like a button
        e.preventDefault();
        // Get the field name
        fieldName = $(this).attr('field');
        // Get its current value
        var currentVal = parseInt($('input[id='+fieldName+']').val());
        // If is not undefined
        if (!isNaN(currentVal)) {
            // Increment
            $('input[id='+fieldName+']').val(currentVal + 1);
        } else {
            // Otherwise put a 0 there
            $('input[id='+fieldName+']').val(1);
        }
    });
    // This button will decrement the value till 0
    $(".qtyminus").click(function(e) {
        // Stop acting like a button
        e.preventDefault();
        // Get the field name
        fieldName = $(this).attr('field');
        // Get its current value
        var currentVal = parseInt($('input[id='+fieldName+']').val());
        // If it isn't undefined or its greater than 0
        if (!isNaN(currentVal) && currentVal > 1) {
            // Decrement one
            $('input[id='+fieldName+']').val(currentVal - 1);
        } else {
            // Otherwise put a 0 there
            $('input[id='+fieldName+']').val(1);
        }
    });

    // $('.pagination__a').on('click', function(){
    //     $('.pagination li').removeClass('active');
    //     $(this).parents('li').addClass('active'); 
    // });

    /*** countdown clock ***/
    // function getTimeRemaining(endtime) {
    //   var t = Date.parse(endtime) - Date.parse(new Date());
    //   var seconds = Math.floor((t / 1000) % 60);
    //   var minutes = Math.floor((t / 1000 / 60) % 60);
    //   var hours = Math.floor((t / (1000 * 60 * 60)) % 24);
    //   var days = Math.floor(t / (1000 * 60 * 60 * 24));
    //   return {
    //     'total': t,
    //     'days': days,
    //     'hours': hours,
    //     'minutes': minutes,
    //     'seconds': seconds
    //   };
    // }
    // function initializeClock(id, endtime) {
    //   var clock = document.getElementById(id);
    //   // var daysSpan = clock.querySelector('.days');
    //   var hoursSpan = clock.querySelector('.hours');
    //   var minutesSpan = clock.querySelector('.minutes');
    //   // var secondsSpan = clock.querySelector('.seconds');
    //   function updateClock() {
    //     var t = getTimeRemaining(endtime);
    //     // daysSpan.innerHTML = t.days;
    //     hoursSpan.innerHTML = ('0' + t.hours).slice(-2);
    //     minutesSpan.innerHTML = ('0' + t.minutes).slice(-2);
    //     // secondsSpan.innerHTML = ('0' + t.seconds).slice(-2);
    //     if (t.total <= 0) {
    //       clearInterval(timeinterval);
    //     }
    //   }
    //   updateClock();
    //   var timeinterval = setInterval(updateClock, 1000);
    // }
    // var deadline = new Date(Date.parse(new Date()) + 15 * 24 * 60 * 60 * 1000);
    // initializeClock('countdown', deadline);

    /*** elevateZoom ***/
    function elevateZoom_plugin () {
        $('.elevateZoom').elevateZoom({
            zoomWindowWidth: 450,
            zoomWindowHeight: 450,
            borderColour: "#fff",
            cursor:"move",
            borderSize: 0
        });  
    }

    /*** Swiper ***/
    var swiper = new Swiper('.swiper--homebanner', {
        nextButton: '.swiper--homebanner .swiper-button-next',
        prevButton: '.swiper--homebanner .swiper-button-prev',
        pagination: '.swiper-pagination',
        paginationClickable: true,
        preventClicks: false,
        preventClicksPropagation: false,
        slidesPerView: 1,
        loop: true,
        autoHeight: true,
        autoplay: 2000,
        effect: 'fade',
        fade: { crossfade: true },
        speed: 1300
    });


    var howManySlides = $('.swiper-wrapper .swiper-slide').length;
    var swiper = new Swiper('.swiper-anotherproduct', {
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        paginationClickable: true,
        preventClicks: false,
        preventClicksPropagation: false,
        slidesPerView: 3,
        spaceBetween: 25,
        breakpoints: {
            1024: {
                spaceBetween: 15
            },
            768: {
                spaceBetween: 5
            },
            480: {
                slidesPerView: 2,
                spaceBetween: 10
            }
        },
        onInit: function(){
            if(howManySlides<4){
                $('.swiper-button-next, .swiper-button-prev').css('display','none');
            }
            else{
                $('.swiper-button-next, .swiper-button-prev').css('display','block');
            }
        }
    });

    /*** bxslider ***/
    var sliderBanner = $('.bxslider--homebanner .bxslider, .bxslider--flowercourse .bxslider, .bxslider--weddingworld .bxslider').bxSlider({
        adaptiveHeight: true,
        mode: 'fade',
        onSliderLoad: function(){
            $(".bxslider--homebanner, .bxslider--flowercourse, .bxslider--weddingworld").css("visibility", "visible");
        }
    });

    // if(window.innerHeight > window.innerWidth){
    //     sliderBanner.reloadSlider();
    // }

    // window.onresize = function (event) {
    //     applyOrientation();
    // }

    // function applyOrientation() {
    //     if (window.innerHeight > window.innerWidth) {
    //         sliderBanner.reloadSlider();
    //     } else {
    //         sliderBanner.reloadSlider();
    //     }
    // }
    // $('.bxslider--anotherproduct .bxslider').bxSlider({
    //     minSlides: 3,
    //     maxSlides: 4,
    //     pager: false,
    //     onSliderLoad: function(){
    //         $(".bxslider--anotherproduct").css("visibility", "visible");
    //     }
    // });
    $('.bxslider--productdetail .bxslider').bxSlider({
        mode: 'fade',
        pagerCustom: '#bx-pager',
        infiniteLoop: false,
        controls: false,
        /*
        To add class on the first visible slide you have to call onSliderLoad. 
        Then you continue adding and removing active-slide class with onSlideAfter call.
        */
        onSlideAfter: function (currentSlideNumber, totalSlideQty, currentSlideHtmlObject) {
            // console.log(currentSlideHtmlObject);
            $('.active-slide').removeClass('active-slide');
            $('.bxslider > li > img').removeClass('elevateZoom');
            $('.bxslider > li').eq(currentSlideHtmlObject + 0).addClass('active-slide')
            $('.bxslider > .active-slide > img').addClass('elevateZoom')
            elevateZoom_plugin();
        },
        onSliderLoad: function () {
            $(".bxslider--productdetail").css("visibility", "visible");
            $('.bxslider > li').eq(0).addClass('active-slide')
            $('.bxslider > .active-slide > img').addClass('elevateZoom')
            elevateZoom_plugin();
            $('.bx-loading').hide();
        }
    });

	/*** owl carousel ***/
	var owl = $('.home__banner > .owl-carousel');
	owl.owlCarousel({
	    items:1,
	    // loop:true,
	    // dots:false,
	    // nav:true,
	    margin:0,
	    autoplay:true,
	    autoplayTimeout:1000,
	    autoplayHoverPause:true,
	    animateOut: 'fadeOut'
	});

    /*** Expand / Collapse payment ***/
    $('.with-step .content__box.active .box__container').slideDown("slow");
    $('.with-step .content__box.active .expand-collapse__link').addClass("active");
    $('.with-step .content__box .expand-collapse__link').click(function(){
        if($(this).parent().hasClass("active")){
            $(this).parent().find('.box__container').slideDown("slow");
        }
        else{
            // $('.with-step .content__box').removeClass('active');
            // $('.with-step .content__box .expand-collapse__link').removeClass('active').parent().find('.box__container').slideUp("slow");
            // // console.log($(this).parent());
            // $(this).addClass('active').parent().addClass('active').find('.box__container').slideDown("slow");
        }
    });

    /*** Expand / Collapse ***/
    $('.default .content__box.active .box__container').slideDown("slow");
    $('.default .content__box.active .expand-collapse__link').addClass("active");
    $('.default .expand-collapse__link').click(function(){        
        if($(this).parent().hasClass("active")){
            $(this).removeClass('active').parent().removeClass('active').find('.box__container').slideToggle("slow");
        }
        else{
            $(this).addClass('active').parent().addClass('active').find('.box__container').slideToggle("slow");
        }
    });

    // collapse mnavbar
    $('.mnavbar__sub__label').click(function(){        
        if($(this).hasClass("active")){
            $(this).find("span").removeClass("ico-min-mini").addClass("ico-plus-mini");            
            $(this).removeClass("active").parent().find('.mnavbar__sub__li').hide();            
        }
        else{
            $(this).find("span").removeClass("ico-plus-mini").addClass("ico-min-mini");            
            $(this).addClass("active").parent().find('.mnavbar__sub__li').show();
        }
    });
});

var isgmcornerdetail = document.getElementsByClassName('map');   
if (isgmcornerdetail.length > 0){
    /*** Gmaps ***/
    // In the following example, markers appear when the user clicks on the map.
    // Each marker is labeled with a single alphabetical character.
    var labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    var labelIndex = 0;

    function initialize() {
        var bangalore = {lat: -6.209310, lng: 106.820715};
        var mapOptions = {
            zoom: 16,
            center: bangalore
            // mapTypeControlOptions: { mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']}
        };
        var map = new google.maps.Map(document.getElementById('map'), mapOptions);
        //Associate the styled map with the MapTypeId and set it to display.
        // map.mapTypes.set('map_style', styledMap);
        // map.setMapTypeId('map_style');
        addMarker(bangalore, map);
    }
    // Adds a marker to the map.
    function addMarker(location, map) {
        // Add the marker at the clicked location, and add the next-available label
        // from the array of alphabetical characters.
        var marker = new google.maps.Marker({
            position: location,
            label: labels[labelIndex++ % labels.length],
            map: map
        });
    }
    google.maps.event.addDomListener(window, 'load', initialize);
}